import React, {useContext, useState, useEffect} from 'react';
import { useRouter } from 'next/router';
import { trpc } from 'utils/trpc';

type CoinbaseState = {
  cartId: string | null,
  status: string | null,
  watching: boolean,
  // startWatching: () => void
  // stopWatching: () => void
};

const defaultInitialState = {
  cartId: null,
  status: null,
  watching: false,
  // startWatching: () => {},
  // stopWatching: () => {}
};

const CoinbaseContext = React.createContext<CoinbaseState>(defaultInitialState);

type Props = {
  children?: React.ReactNode
};

const CoinbaseProvider: React.FC<Props> = (props) => {

  const router = useRouter();
  const [watching, setWatchingInternal] = useState<boolean>(false);

  // Get Status
  const statusQuery = trpc.coinbase.checkPaymentStatus.useQuery({
    store_id: parseInt(process.env.NEXT_PUBLIC_STORE_ID as string),
    use_cart_id: router.query.cart_id as string
  }, {enabled: router.isReady === true});

  const cartId = statusQuery.data?.cart_id ?? null;
  const status = statusQuery.data?.status ?? null;

  // When cartId is set by router
  useEffect(() => {
    statusQuery.refetch();
  }, [router.query.cart_id]);

  // When Status changes
  useEffect(() => {

    console.log("Coinbase: Status changed", status);

    // If status is pending, start watching
    if(status === 'pending'){
      console.log("Coinbase: Start watching");
      setWatchingInternal(true);
    }

    // If status is confirmed, stop watching
    if(status === 'confirmed'){
      console.log("Coinbase: Stop watching");
      setWatchingInternal(false);
    }

  }, [status]);


  // When watching changes
  useEffect(() => {

    let interval: NodeJS.Timeout;
  
    // Start polling 
    if(watching){
      interval = setInterval(async () => {
        statusQuery.refetch();
      }, 5000);
    }
  
    // Clear interval on unmount or when watching changes to false
    return () => {
      if (interval) clearInterval(interval);
    }
  
  }, [watching]);

  const ctx: CoinbaseState = {
    cartId,
    status,
    watching
  }

  return (
    <CoinbaseContext.Provider value={ctx}>
      <>{props.children}</>
    </CoinbaseContext.Provider>  
  )

}

const useCoinbase = () => {
  const context = useContext(CoinbaseContext)
  return context
}

export type { CoinbaseState }
export default useCoinbase
export { CoinbaseContext, CoinbaseProvider }