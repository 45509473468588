
import getColor from 'utils/getThemeColor'

const colors = {};

const colorVals: ObjectOf<colorVal> = {
  sand: [
    {stop: 0, h: 51, s: 28},
    {stop: 100, h: 51, s: 25}
  ],
  green: [
    {stop: 0, h: 127, s: 47},
    {stop: 100, h: 127, s: 47}
  ],
  purple: [
    {stop: 0, h: 264, s: 76},
    {stop: 80, h: 264, s: 76}
  ],
  gray: [
    {stop: 0, h: 0, s: 0},
    {stop: 100, h: 0, s: 0}
  ]
};

const breakpoints = [0, 480, 768, 1000, 1300, 1600, 1900];

const spacing = {
  outerGutterWidth: ['1rem', '1rem', '1.5rem', '1.5rem', '2rem', '2rem'],
  pageWrapperWidth: ['0', '0', '0', '0', '0', '100rem']
};

const typography = {
  fontFamilyDefault: '"Inter", Arial, Helvetica, sans-serif',
  fontFamilySecondary: '"Roboto Condensed", Arial, Helvetica, sans-serif'
};

const theme = {
  typography, 
  colors, 
  spacing, 
  breakpoints
};

const color = {
  get: getColor,
  sand: (lightness) => getColor(colorVals.sand, lightness),
  green: (lightness) => getColor(colorVals.green, lightness),
  purple: (lightness) => getColor(colorVals.purple, lightness),
  gray: (lightness) => getColor(colorVals.gray, lightness)
};


const bp = (index: number, upto = false) => {
  return breakpoints[upto ? index : index-1] + 'px'
};

export {bp, color};

export default theme; 