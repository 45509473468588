import React, { ReactElement, useState, useMemo } from 'react';
import { NextPage } from'next';
import { useSession, signOut } from 'next-auth/react';

type Props = {
  roles?: string[],
  children: React.ReactNode
};

const ManageAuthGate: NextPage<Props> = (props) => {

  // if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
  const { status, data } = useSession({ required: true })

  const handleSignOut = () => {
    signOut();
  }

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  // Roles
  if(props.roles?.includes('admin')){
    if(!data.roles?.includes('admin')){
      return (
        <div style={styles.container}>
          <div>Sorry, you do not have permission to access this page</div>
          <button onClick={handleSignOut} style={styles.button}>Sign in with a different account</button>
        </div>
      );
    }
  }

  return <>{props.children}</>;

};

export default ManageAuthGate;



// Styles
const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    fontSize: '1rem',
    lineHeight: '2rem',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '2.5rem',
    fontSize: '1rem',
    padding: '0 1rem',
    marginTop: '1rem',
    border: 'none',
    backgroundColor: '#000',
    color: '#fff',
    cursor: 'pointer'
  }
}